import {
	Listbox,
	ListboxOption,
	ListboxOptions,
	ListboxButton,
} from '@headlessui/react'
import { CaretDown } from '../icons'
import { TypographyCaps, TypographyLabel } from '../typography'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

export type selectOption = {
	name: string
	icon: any
	slug: string
	symbol?: string
	version: string
}

export default function Dropdown({
	options = [],
	title,
	placeholder = 'Select an option',
	selected,
	onSelectionChange,
	variant = 'default',
	containerClasses = '',
	additionalClasses = '',
}: {
	options: selectOption[]
	title?: string
	placeholder?: string
	selected: selectOption | null
	onSelectionChange: (selected: selectOption) => void
	variant?: 'default' | 'input'
	containerClasses?: string
	additionalClasses?: string
}) {
	const handleChange = v => {
		v && onSelectionChange(v)
	}
	return (
		<>
			{title && (
				<TypographyCaps
					size="small"
					additionalClasses={clsx(
						variant === 'default'
							? 'text-textSecondaryLight dark:text-textSecondaryDark'
							: 'block leading-6 text-grey400 dark:text-grey500',
					)}
				>
					{title}
				</TypographyCaps>
			)}
			<Listbox
				value={selected}
				onChange={handleChange}
				// by={(a, z) => a.name === z.name}
			>
				{({ open }) => (
					<div className={clsx('relative', containerClasses)}>
						<ListboxButton
							className={twMerge(
								variant === 'default'
									? 'h-10 w-full border border-textInactiveLight hover:border-textPrimaryLight focus:border-textPrimaryLight dark:border-textSecondaryDark dark:hover:border-textPrimaryDark dark:focus:border-textPrimaryDark'
									: 'block w-full rounded-md border-0 px-4 py-3 font-abc text-paragraphMedium outline-0 ring-1 ring-inset drop-shadow-menu dark:drop-shadow-none',
								variant === 'default'
									? 'text-textPrimaryLight dark:text-textPrimaryDark'
									: 'text-textPrimaryLight ring-textInactiveLight dark:text-textPrimaryDark dark:ring-textInactiveDark',
								'text-lef relative cursor-pointer rounded-md py-1.5 pl-3 pr-10 transition-all',
								variant === 'default' && 'bg-white dark:bg-dark',
								variant !== 'default' &&
									'cursor-pointer bg-white text-grey400 placeholder:text-grey400 dark:bg-uiTertiaryDark dark:text-grey200 dark:placeholder:text-grey600',
								variant !== 'default' &&
									'hover:ring-textSecondaryLight dark:hover:placeholder-textSecondaryDark dark:hover:ring-textSecondaryDark',
								// Focus
								variant !== 'default' &&
									'focus:text-textPrimaryLight focus:placeholder-textPrimaryLight focus:ring-textPrimaryLight dark:focus:text-textPrimaryDark dark:focus:placeholder-textPrimaryDark dark:focus:ring-textPrimaryDark',
								additionalClasses,
							)}
						>
							<span className="flex items-center">
								<span className="block">
									<TypographyLabel
										size="medium"
										isBold={false}
										additionalClasses={
											selected ? '' : 'text-grey400 dark:text-grey600'
										}
									>
										{selected ? selected.name : placeholder}
									</TypographyLabel>
								</span>
								<span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-3.5">
									<CaretDown
										className={clsx(
											'h-5 w-5 transform transition-all ease-linear',
											open ? 'rotate-180' : 'rotate-0',
											// open
											// 	? 'text-textPrimaryLight dark:text-textPrimaryDark'
											// 	: 'text-textPrimaryLight dark:text-textPrimaryDark',
										)}
										aria-hidden="true"
									/>
								</span>
							</span>
						</ListboxButton>
						{/* )} */}
						<ListboxOptions
							modal={false}
							// anchor="bottom"
							// transition
							className={clsx(
								'absolute z-10 w-full max-w-[603px] py-4',
								' ring-none shadow-md',
								// 'origin-top transition duration-200 ease-in-out',
								// open ? 'scale-100 opacity-1' : 'scale-95 opacity-0',
								'mt-1 overflow-auto rounded-2xl text-base ring-0 focus:outline-none',
								variant === 'default' &&
									'text-textPrimaryDark dark:text-textPrimaryLight',
								variant === 'default'
									? 'bg-uiTertiaryDark dark:bg-uiTertiaryLight'
									: 'bg-uiTertiaryLight dark:bg-bgInputDark dark:text-textPrimaryDark',
							)}
						>
							{options.map(option => (
								<ListboxOption
									key={option.name}
									value={option}
									className={clsx(
										'flex h-10 w-full cursor-pointer px-4',
										// 'group relative cursor-pointer select-none',
										'hover:bg-uiPrimaryDark hover:dark:bg-white ',
										'hover:text-textSecondaryDark hover:dark:text-textSecondaryLight',
									)}
								>
									<div className="flex w-full items-center text-center">
										{option.icon && (
											<div className="flex w-8 items-center justify-center">
												{option.icon}
											</div>
										)}
										<span className={clsx('block w-full text-left')}>
											<TypographyLabel size="medium" isBold={true}>
												{option.name}
											</TypographyLabel>
										</span>
									</div>
								</ListboxOption>
							))}
						</ListboxOptions>
					</div>
				)}
			</Listbox>
		</>
	)
}
